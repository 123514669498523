import { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
// import data
import linksMapData from "../../data/linksMap.json";
import exploreSolutionsData from "../../data/exploreSolutions.json";
import { DataStructure, DataFlat } from '../../types';
// import components
import { MainFrame, SpacingFrame, ColAutoFrame } from "../display/Frame";
import { TitleSubtitle } from "../display/TextFormat";
import { OtherServicesCard } from "../display/Cards";
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { maxWidthScreen, typedVocab } from "../GlobalVar";

const typedLinks : DataFlat = linksMapData;
const typedExploreSolutions : DataStructure = exploreSolutionsData;

interface NavTreeProps {
    addLink?: string
};
export function NavTree({ addLink } : NavTreeProps) {
    const location = useLocation();
    const { lang } = useLang();
    const { screenSize } = useScreenSize();
    const contentRef = useRef<HTMLDivElement>(null);
    const [pathList, setPathList] = useState<string[]>([]);
    const [pathLen, setPathLen] = useState<number>(1);
    const [showDots, setShowDots] = useState<boolean>(false);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(true);

    const hoverStyle = "hover:text-teal-400 hover:underline hover:decoration-teal-400 text-nowrap duration-300 ease-in-out";

    useEffect(() => {
        let pathName = location.pathname;
        pathName = pathName.replace("/tw", "");
        pathName = pathName.replace("/jp", "");
        setPathList(pathName.split("/"));
    }, [location]);

    useEffect(() => {
        setPathLen(pathList.length);
    }, [pathList]);

    useEffect(() => {
        if (isOverflowing && pathLen > 3) {
            setShowDots(true);
        } else {
            setShowDots(false);
        };
    }, [isOverflowing]);

    useEffect(() => {
        function checkOverflow() {
            if (contentRef.current) {
                const element = contentRef.current;
                if (screenSize === "Large") {
                    const hasOverflow = element.scrollWidth >= maxWidthScreen;
                    setIsOverflowing(hasOverflow);
                } else if (screenSize === "Small") {
                    const computedStyle = getComputedStyle(element);
                    const maxWidthValue = parseFloat(computedStyle.maxWidth);
                    const hasOverflow = element.scrollWidth >= maxWidthValue;
                    setIsOverflowing(hasOverflow);
                };
            };
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow); // Also check on window resize

        return () => window.removeEventListener('resize', checkOverflow);
    }, [pathList, screenSize]);

    function extractPath(keyWord: string) {
        let resultPath = "";

        for (let i = 0; i < pathList.length; i++) {
            const segment = pathList[i];
            if (segment) { // Check if segment is not an empty string
                // Append the current segment to the result path
                resultPath += segment; 
                // If the segment matches the keyWord, stop adding further segments
                if (segment === keyWord) { 
                    break;
                };
                if (i < pathList.length - 1) { // If this is not the last segment, append a slash
                    resultPath += "/";
                };
            };
        };
        return resultPath;
    };

    let path = pathList.map((i, index) => {
        index += 1;
        let maxNum = pathLen - 2
        if (i) {
            if (isOverflowing && pathLen > 3) {
                if (index > maxNum) {
                    let name = typedLinks[i][lang];
                    let link = extractPath(i);
                    return (
                        <>
                            <FontAwesomeIcon className="text-sm my-auto" icon={faChevronRight} />
                            <Link className={`${hoverStyle} ${index === pathLen && "truncate"}`} to={ lang === "Zh" ?  `/tw/${link}` : lang === "Jp" ?  `/jp/${link}` : `/${link}`}>
                                { name }
                            </Link>
                        </>
                    )
                };
            } else {
                try {
                    let name = typedLinks[i][lang];
                    let link = extractPath(i);
                    return (
                        <>
                            <FontAwesomeIcon className="text-sm my-auto" icon={faChevronRight} />
                            <Link className={`${hoverStyle} ${isOverflowing && index === pathLen && "truncate"}`} to={ lang === "Zh" ?  `/tw/${link}` : lang === "Jp" ?  `/jp/${link}` : `/${link}`}>
                                { name }
                            </Link>
                        </>
                    )
                } catch {
                    return (
                        <>
                            <FontAwesomeIcon className="text-sm my-auto" icon={faChevronRight} />
                        </>
                    )
                }
            };
        };
    });
    
    return (
        <div className="w-full h-fit overflow-x-hidden">
            <div className="m-auto" style={{ maxWidth: `${maxWidthScreen}px` }}>
                <div ref={contentRef} className="max-w-full w-fit h-fit flex items-start justify-start gap-2 py-4 lg:px-0 sm:px-8 text-base cursor-pointer"> 
                    <Link className={hoverStyle} to={ lang === "Zh" ? "/tw" : lang === "Jp" ? "/jp" : "/" }>{ typedVocab["Home"][lang] }</Link>
                    { showDots && 
                        <>
                            <FontAwesomeIcon className="text-sm my-auto" icon={faChevronRight} /> 
                            <p className="text-sm">...</p>
                        </>
                    }
                    { path }
                    { addLink && <p className={`${hoverStyle} ${isOverflowing && "truncate"}`}>{ addLink }</p> }
                </div>
            </div>
        </div>
        
    )
};

interface ExploreOtherServicesProps {
    titleKey: string;
    servicesList: string[];
};
export function ExploreOtherServices({ titleKey, servicesList } : ExploreOtherServicesProps) {
    const { lang } = useLang();
    const [singleService, setSingleService] = useState<boolean>(false);

    useEffect(() => {
        if (servicesList.length === 1) {
            setSingleService(true);
        } else {
            setSingleService(false);
        };
    }, [servicesList])

    let card = servicesList.map((i) => {
        return (
            <OtherServicesCard data={ typedExploreSolutions[i] } imagePath="image/" />
        )
    });

    let content = singleService
                ? <>
                    <ColAutoFrame>
                        <div className="my-auto">
                            <TitleSubtitle title={ typedVocab[titleKey][lang] } left />
                        </div>
                        { card }
                    </ColAutoFrame>
                </>
                : <>
                    <TitleSubtitle title={ typedVocab[titleKey][lang] } />
                    <ColAutoFrame>
                        { card }
                    </ColAutoFrame>
                </>

    return (
        <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
            <SpacingFrame>
                { content }
            </SpacingFrame>
        </MainFrame>
    )
};