import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import icons
import { Clock, MapPin, Phone, Mail } from "lucide-react";
// import data
import contentData from "../../data/content/careers/careersContent.json";
import positionsData from "../../data/content/careers/openPositions.json";
import { DataStructure } from "../../types";
// import components
import { ColAutoFrame, MainFrame, SpacingFrame } from "../display/Frame";
import { TitleSubtitle, TextBody, ShowIconText, TextParagraphHeader, TextBulletList } from "../display/TextFormat";
import { LeftBorderCard, PositionCard } from "../display/Cards";
import { MoreButton } from "../tools/Buttons";
import { NavTree } from "../navigation/NavLinkPages";
import { AboutDefaultBanner } from "./About/About";
import NoContent, { LoadingContent } from "../content/NoContent";
// import context
import { useLang } from "../Context";
// import global var
import { maxWidthScreen, typedVocab } from "../GlobalVar";
import navUnitsData from "../../data/navUnits.json";

const typedContent : DataStructure = contentData;
const typedPositions : DataStructure = positionsData;
const typedNavUnitsData : DataStructure = navUnitsData;


function DefaultBanner() {
    const { lang } = useLang();
    return (
        <AboutDefaultBanner title={ typedContent["Careers"]["title"][lang] } subtitle={ typedContent["Careers"]["subtitle"][lang] } />
    )
};

interface ShowOpenPositionsProps {
    title: string;
    location?: string;
};
function ShowOpenPositions({ title, location } : ShowOpenPositionsProps) {
    // position cards
    let positionCard = Object.keys(typedPositions).map((i) => {
        if (i !== location) {
            return (
                <PositionCard data={typedPositions[i]} link={"/careers/" + i} />
            )
        }
    });

    return (
        <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
            <SpacingFrame>
                <TitleSubtitle title={ title } />
                <div className="w-full">
                    <ColAutoFrame>
                        { positionCard }
                    </ColAutoFrame>
                </div>
            </SpacingFrame>
        </MainFrame>
    )
};

function EmailUs() {
    const { lang } = useLang();
    return (
        <>
            <div>            
                <TextBody text={ typedContent["Careers"]["text"][lang] } />
            </div>
            <MoreButton label="Email Today" link={`mailto:${typedNavUnitsData["Company_Email"][lang]}`} />
        </>
    )
};



export default function Careers() {
    const { lang } = useLang();
    const data = typedContent["Careers"];
    const section1 = data[1];
    const section2 = data[2];

    return (
        <>
            <DefaultBanner />
            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <TextBody text={ section1["text"][lang] } />
                    <p className="text-left text-lg font-semibold">{ section1["subtitle"][lang] }</p>
                    <LeftBorderCard data={ section1["content"] } isBulletList />
                    <EmailUs />
                </SpacingFrame>
            </MainFrame>

            <ShowOpenPositions title={ section2["title"][lang] } />
        </>
    )
};


export function OpenPositions() {
    const { lang } = useLang();
    const { openPositions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [navTitle, setNavTitle] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (openPositions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedPositions[openPositions] || undefined);
        };
    }, [openPositions]);

    useEffect(() => {
        if (data) {
            setNavTitle(data["Role"][lang] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };
    
    return (
        <>
            <DefaultBanner />
            { navTitle && <NavTree addLink={navTitle} /> }

            { data && 
                <MainFrame>
                    <SpacingFrame spacing={40}>
                        <SpacingFrame spacing={16}>
                            <TitleSubtitle title={ data["Role"][lang] } left />
                            <div className="flex lg:flex-row sm:flex-col gap-4 pb-6 border-b-1 border-black-20">
                                <ShowIconText text={ data["Unit"][lang] } iconName="briefcase" />
                                <ShowIconText text={ data["Location"][lang] } iconName="map-pin" />
                                <ShowIconText text={ data["Level"][lang] } iconName="chart-no-axes-column" />
                            </div>
                        </SpacingFrame>

                        <SpacingFrame>
                            <TextBody title={ typedVocab["Overview"][lang] } text={ data["Overview"]["text"][lang] } />
                        </SpacingFrame>
                        <SpacingFrame>
                            <p className="text-lg text-left font-semibold">{ typedVocab["Duties"][lang] }</p>
                            <TextBulletList list={ data["Duties"]["text"][lang] } />
                        </SpacingFrame>
                        <SpacingFrame>
                            <p className="text-lg text-left font-semibold">{ typedVocab["Required"][lang] }</p>
                            <TextBulletList list={ data["Required"]["text"][lang] } />
                        </SpacingFrame>
                        { data["Desired"]["text"][lang].length !== 0 &&
                            <SpacingFrame>
                                <p className="text-lg text-left font-semibold">{ typedVocab["Desired"][lang] }</p>
                                <TextBulletList list={ data["Desired"]["text"][lang] } />
                            </SpacingFrame>
                        }
                        <EmailUs />
                    </SpacingFrame>
                </MainFrame>
            }

            <ShowOpenPositions title="More Positions" location={ openPositions } />
        </>
    )
};


export function ContactUs() {
    const { lang } = useLang();
    return (
        <>
            <div className="relative w-full lg:h-screen sm:h-[1000px] bg-cover bg-center bg-grey-800 bg-blend-color-burn overflow-hidden">
                <div className="absolute w-[1408px] h-[1263px] rounded-full blur-3xl filter opacity-60 -top-[3%] left-[32%]" style={{ backgroundColor: "#037C84" }}/>
                <div className="absolute w-[1088px] h-[976px] rounded-full blur-3xl filter opacity-60 top-[45%] left-[0.5%]" style={{ backgroundColor: "#5B68DF" }}/>

                <div className="absolute flex w-full m-auto lg:mt-0 sm:mt-20">
                    <div className="lg:grid lg:grid-cols-3 sm:flex sm:flex-col gap-8 w-full h-screen m-auto lg:p-0 sm:p-8" style={{ maxWidth: `${maxWidthScreen}px` }}>
                        <div className="col-span-2 flex flex-col gap-20 my-auto">
                            <div>
                                <p className="text-left text-2xl text-white font-bold">{ typedVocab["Lets Talk"][lang] }</p>
                                <p className="text-left text-lg text-white">{ typedVocab["Join Us 2"][lang] }</p>
                            </div>

                            <div className="lg:grid lg:grid-cols-2 lg:grid-rows-1 sm:flex sm:flex-col gap-10 lg:m-0 sm:m-auto">
                                <div className="flex gap-4 w-full p-4">
                                    <Clock className="text-white" width={32} height={32} />
                                    <div className="text-left flex flex-col gap-2">
                                        <p className="text-left text-lg text-white font-semibold">{ typedVocab["Office Hours"][lang] }</p>
                                        <p className="text-left text-sm text-white">{ typedVocab["Working Days"][lang] }<br/>{ typedVocab["Working Hours"][lang] }</p>
                                    </div>
                                </div>

                                <div className="flex gap-4 w-full p-4">
                                    <MapPin className="text-white" width={32} height={32} />
                                    <div className="text-left flex flex-col gap-2">
                                        <p className="text-left text-lg text-white font-semibold">{ typedVocab["Our Address"][lang] }</p>
                                        <p className="text-left text-sm text-white">{ typedNavUnitsData["Company_Address"][lang][0] }</p>
                                        <p className="text-left text-sm text-white">{ typedNavUnitsData["Company_Address"][lang][1] }</p>
                                    </div>
                                </div>

                                <div className="flex gap-4 w-full p-4">
                                    <Phone className="text-white" width={32} height={32} />
                                    <div className="text-left flex flex-col gap-2">
                                        <p className="text-left text-lg text-white font-semibold">{ typedVocab["Phone Number"][lang] }</p>
                                        <p className="text-left text-sm text-white">{typedNavUnitsData["Company_Phone"][lang][0]}</p>
                                        <p className="text-left text-sm text-white">{typedNavUnitsData["Company_Phone"][lang][1]}</p>
                                    </div>
                                </div>

                                <div className="flex gap-4 w-full p-4">
                                    <Mail className="text-white" width={32} height={32} />
                                    <div className="text-left flex flex-col gap-2">
                                        <p className="text-left text-lg text-white font-semibold">{ typedVocab["E-mail Address"][lang] }</p>
                                        <a href={`mailto:${typedNavUnitsData["Company_Email"][lang]}`} className="text-left text-sm text-white">{typedNavUnitsData["Company_Email"][lang]}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-1"></div>
                    </div>
                </div>
            </div>
        </>
    )
};