import { Link } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faLinkedinIn, faYoutube, faXTwitter } from "@fortawesome/free-brands-svg-icons";
// import images
import logoName from "../../image/logo_name.svg";
// import data
import navUnitsData from "../../data/navUnits.json";
import { DataStructure } from '../../types';
// import components
import { ShowAllBusinessUnits, ShowUnits } from './NavDisplays';
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { maxWidthNav, typedVocab } from "../GlobalVar";


const typedNavUnitsData : DataStructure = navUnitsData;

export default function Footer() {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();

    return (
        <div className="w-full h-fit grid gap-8 bg-black/95 lg:px-32 lg:py-10 sm:px-8 sm:py-10">
            <div className="m-auto grid gap-10 lg:grid-cols-5 sm:grid-flow-row sm:auto-rows-min" style={{ maxWidth: `${maxWidthNav}px` }}>
                <div className="col-span-1 grid grid-flow-row auto-rows-min gap-10">
                    <Link to={ lang === "Zh" ? "/tw" : "/"}>
                        <img className="lg:ml-auto cursor-pointer" src={logoName} alt="logo-name-footer" />
                    </Link>
                    <div className="w-fit grid gap-2 text-sm">
                        <p className="text-white lg:text-right sm:text-left">{typedNavUnitsData["Company_Address"][lang][0]}</p>
                        <p className="text-white lg:text-right sm:text-left">{typedNavUnitsData["Company_Phone"][lang][0]}</p>
                        <p className="text-white lg:text-right sm:text-left">{typedNavUnitsData["Company_Address"][lang][1]}</p>
                        <p className="text-white lg:text-right sm:text-left">{typedNavUnitsData["Company_Phone"][lang][1]}</p>
                        <p className="text-fit text-white lg:text-right sm:text-left">
                            <a className="lg:text-right sm:text-left" href={`mailto:${typedNavUnitsData["Company_Email"][lang]}`}>{typedNavUnitsData["Company_Email"][lang]}</a>
                        </p>
                    </div>
                    <div className="flex gap-5 text-white lg:justify-end lg:items-end">
                        <SocialsIcon icon={faFacebookF} link="https://www.facebook.com/GraphenAI" />
                        <SocialsIcon icon={faLinkedinIn} link="https://www.linkedin.com/company/graphenai/mycompany/" />
                        <SocialsIcon icon={faYoutube} link="https://www.youtube.com/@Graphen" />
                        <SocialsIcon icon={faXTwitter} link="https://x.com/GraphenAI" />
                    </div>
                </div>
                <div className="col-span-4 grid gap-10 lg:grid-cols-4 sm:grid-flow-row sm:auto-rows-min">
                    { screenSize === "Small" &&
                        <div className="text-white">
                            <p className="text-left text-white font-bold mb-2">{typedVocab["Company"][lang]}</p>
                            <div className="grid gap-1 text-sm">
                                <ShowUnits data={typedNavUnitsData["Company"][lang]} />
                            </div>
                        </div>
                    }
                    <ShowAllBusinessUnits isFooter />
                </div>
            </div>
            <div className="w-full grid grid-flow-col auto-cols-auto lg:border-t-1 lg:border-white/20 sm:border-0 py-4 m-auto" style={{ maxWidth: `${maxWidthNav}px` }}>
                { screenSize === "Large" && <ShowUnits data={typedNavUnitsData["Company"][lang]} /> }
                <p className="text-white text-sm">
                    <span><FontAwesomeIcon icon={faCopyright} /></span>
                    <span> 2025 Graphen Inc. All rights reserved.</span>
                </p>
            </div>
        </div>
    )
};

interface SocialsIconProps {
    icon: IconDefinition;
    link: string;
};
export function SocialsIcon({ icon, link } : SocialsIconProps) {
    return (
        <a className="flex items-center justify-center" href={link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={icon} />
        </a>
    )
};