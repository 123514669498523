import { useState, useEffect, useRef, useLayoutEffect, ReactNode } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Globe } from 'lucide-react';
// import images
import logoName from "../../image/logo_name.svg";
// import data
import navUnitsData from "../../data/navUnits.json";
import { DataStructure } from '../../types';
// import components
import { NavToggleButton, AllGraphen, LanguageSelect, OtherTabs, MobileDropdownUnit } from "./NavDisplays";
// import context
import { useNavTab, useLang, useScreenSize, useMobileNavOpen } from "../Context";
// import global var
import { maxWidthNav, typedVocab } from "../GlobalVar";

const typedNavUnits : DataStructure = navUnitsData;

export default function Nav() {
    const { screenSize } = useScreenSize();
    return (
        <>
            { screenSize === "Large" ? <NavBar /> : <MobileNavBar /> }
        </>
    )
};

export function NavBar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const { lang } = useLang();
    const { navTab } = useNavTab();

    useEffect(() => {
        function handleScroll() {
            const scrollTop = document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0); 
        };
        // Listen for scroll events
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    let style = navTab["Open"]
                ? "h-fit px-32 py-3 bg-black/95 border-b-1 border-grey-300"
                : "h-fit px-32 py-3 bg-black/95";

    return (
        <div className="fixed top-0 w-full z-50">
            <motion.div className={style}
                animate={{ backgroundColor: isScrolled || navTab["Open"] ? "rgba(0, 0, 0, 95)" : "rgba(0, 0, 0, 0)" }}
                transition={{ duration: 0.3 }}
            >
                <div className="m-auto flex" style={{ maxWidth: `${maxWidthNav}px` }}>
                    <Link to={ lang === "Zh" ? "/tw" : "/" }>
                        <img className="mr-5 cursor-pointer" src={logoName} alt="logo-name" />
                    </Link>
                    <div className="mr-auto flex items-center justify-center">
                        <LeftNav />
                    </div>
                    <div className="ml-auto flex items-center justify-center">
                        <RightNav />
                    </div>
                </div>
                
            </motion.div>
            { navTab["Open"] && <NavDropdown /> }
        </div>
    )
};

export function NavDropdown() {
    const childRef = useRef<HTMLDivElement>(null);
    const [paddingBottom, setPaddingBottom] = useState(20);
    const [content, setContent] = useState<ReactNode>(<></>);
    const { navTab } = useNavTab();

    useLayoutEffect(() => {
        if (navTab["Name"] === "Business Units") {
            setContent(<AllGraphen />)
        } else if (navTab["Name"] === "Language") {
            setContent(<LanguageSelect />)
        } else {
            setContent(<OtherTabs ref={childRef} />)
        };
    }, [navTab, childRef]);

    useLayoutEffect(() => {
        const timeoutId = setTimeout(() => {
            if (childRef.current) {
                const rect = childRef.current.getBoundingClientRect();
                if (rect.height > 0) {
                    setPaddingBottom(rect.height);
                }
            } else {
                setPaddingBottom(20);
            }
        }, 5); // slight delay to ensure rendering
        return () => clearTimeout(timeoutId);
    }, [content, navTab]);

    return (
        <div className="h-fit bg-black px-32 pt-5" style={{ paddingBottom: `${paddingBottom}px`}}>
            <div className="m-auto" style={{ maxWidth: `${maxWidthNav}px` }}>
                { content }
            </div>
        </div>
    )
};

export function LeftNav() {
    const { lang } = useLang();
    return (
        <div className="w-fit h-fit grid grid-flow-col auto-cols-auto gap-5">
            <NavToggleButton id="Business Units" children={ <p className="text-white">{typedVocab["Business Units"][lang]}</p> } />
            <NavToggleButton id="About" children={ <p className="text-white">{typedVocab["About"][lang]}</p> } />
            <NavToggleButton id="Updates" children={ <p className="text-white">{typedVocab["Updates"][lang]}</p> } />
        </div>
    )
};

export function RightNav() {
    const { lang } = useLang();

    let languageTab = <>
                        <Globe size={14} />
                        <p className="text-white">{typedNavUnits["Language"][lang]}</p>
                    </>

    return (
        <div className="w-fit h-fit grid grid-flow-col auto-cols-auto gap-5 text-white">
            <div className="cursor-pointer select-none">
                <Link to={ lang === "Zh" ? "/tw/contact-us" : "/contact-us"} className="text-white">{typedVocab["Contact"][lang]}</Link>
            </div>
            <div className="cursor-pointer select-none">
                <Link to={ lang === "Zh" ? "/tw/careers" : "/careers"} className="text-white">{typedVocab["Careers"][lang]}</Link>
            </div>
            <NavToggleButton id="Language" children={ languageTab } />
        </div>
    )
};


/////////////////
// MOBILE NAV //
////////////////
export function MobileNavBar() {
    const { mobileNavOpen, setMobileNavOpen } = useMobileNavOpen();
    const { lang } = useLang();
    const headerRef = useRef<HTMLDivElement>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isLangOpen, setIsLangOpen] = useState<boolean>(false);
    const [headerHeight, setHeaderheight] = useState<number>(0);

    useEffect(() => {
        if (!mobileNavOpen) {
            setIsMenuOpen(false);
            setIsLangOpen(false);
        };
    }, [mobileNavOpen]);

    useEffect(() => {
        if (isMenuOpen || isLangOpen) {
            setMobileNavOpen(true);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        };
    }, [isMenuOpen, isLangOpen]);

    useEffect(() => {
        handleResize();
        function handleResize() {
            if (headerRef.current) {
                const element = headerRef.current;
                const height = element.scrollHeight;
                setHeaderheight(height);
            };
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    let languageStyle = isLangOpen
                        ? "gap-1 grid grid-flow-col auto-cols-min flex items-center justify-center cursor-pointer pb-1 border-b-2 border-grey-300"
                        : "gap-1 grid grid-flow-col auto-cols-min flex items-center justify-center cursor-pointer pb-1 border-b-2 border-transparent"
    let languageTab = <div className={ languageStyle }>
                        <Globe size={16} />
                        <p className="text-white select-none text-nowrap">{typedNavUnits["Language"][lang]}</p>
                    </div>

    function openDropdown(type: string) {
        if (type === "menu") {
            setIsMenuOpen(!isMenuOpen);
            setIsLangOpen(false);
        } else if (type === "lang") {
            setIsLangOpen(!isLangOpen);
            setIsMenuOpen(false);
        };
    };

    return (
        <div className="fixed top-0 w-screen z-50 overflow-hidden">
            <div ref={headerRef} className="w-full gap-auto grid grid-flow-col grid-cols-4 flex items-center justify-center bg-black/95 p-3">
                <div className="col-span-1 mr-auto cursor-pointer" onClick={() => openDropdown("menu")}>
                    <FontAwesomeIcon className="text-xl text-white" icon={ isMenuOpen ? faXmark : faBars } />
                </div>
                <Link to={ lang === "Zh" ? "/tw" : "/"} className="col-span-2 m-auto">
                    <img className="cursor-pointer" src={logoName} alt="logo-name" />
                </Link>
                <div className="col-span-1 ml-auto text-white" onClick={() => openDropdown("lang")}>
                    { languageTab }
                </div>
            </div>
            <div className="relative w-full h-full overflow-y-auto" style={{ maxHeight: `calc(100vh - ${headerHeight}px)` }}>
                { isMenuOpen && <MobileMenuDropdown /> }
                { isLangOpen && <MobileLangDropdown /> }
            </div>
        </div>
    )
};

interface MobileNavDropdownFrameProps {
    children: ReactNode;
};
export function MobileNavDropdownFrame({ children } : MobileNavDropdownFrameProps) {
    return (
        <div className="h-full bg-black border-t-1 border-grey-300 select-none">
            { children }
        </div>
    )
};

export function MobileMenuDropdown() {
    const { lang } = useLang();
    return (
        <MobileNavDropdownFrame>
            <MobileDropdownUnit id="Business Units" label={typedVocab["Business Units"][lang]} toggle />
            <MobileDropdownUnit id="About" label={typedVocab["About"][lang]} toggle />
            <MobileDropdownUnit id="Updates" label={typedVocab["Updates"][lang]} toggle />
            <Link to={ lang === "Zh" ? "/tw/contact-us" : "/contact-us" }>
                <MobileDropdownUnit id="Contact" label={typedVocab["Contact"][lang]} />
            </Link>
            <Link to={ lang === "Zh" ? "/tw/careers" : "/contact-us" }>
                <MobileDropdownUnit id="Careers" label={typedVocab["Careers"][lang]} />
            </Link>
        </MobileNavDropdownFrame>
    )
};

export function MobileLangDropdown() {
    const { lang, setLang } = useLang();

    let showLang =  Object.keys(typedNavUnits["Language"]).map((i) => {
        let style = lang === i
                    ? "text-left cursor-pointer hover:text-teal-300 text-teal-300"
                    : "text-left cursor-pointer hover:text-teal-300 text-white"
        return (
            <p id={i} className={style} onClick={() => setLang(i)}>
                {typedNavUnits["Language"][i]}
            </p>
        )
    });

    return (
        <MobileNavDropdownFrame>
            <div className="w-full px-5 py-3">
                <div className="w-full py-3 border-b-1 border-grey-300">
                    <p className="text-md text-left text-white font-bold">{typedVocab["Select Language"][lang]}</p>
                </div>
                <div className="grid grid-flow-row auto-rows-auto gap-1 text-white py-2">
                    { showLang }
                </div>
            </div>
        </MobileNavDropdownFrame>
    )
}